<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" t-data="loading-dom"/>
      <v-alert-error
      :show="isError"
      :close="closeAlert"
      :msg="errorMessage"
      t-data="error-dom"
    ></v-alert-error>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">Edit Force Update</h3>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 button-bold" color="whiteColor" t-data="cancel-btn" @click="showCancelPopup">
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaveButtonDisable()"
            class="ma-2 save-package-button sub-body-bold"
            color="primary600Color"
            t-data="save-btn"
            @click="save"
          >
            <v-icon left light>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title class="mt-0 pt-0 pb-0">
        <h6 class="mb-0 card-title">
          Force Update Information
        </h6>
        <v-spacer></v-spacer>
        <p class="edit-field-label sub-body-bold mr-4" t-data="version-info-label">Status</p>
        <v-switch
          color="primary"
          v-model="appVersionObject.status"
          :false-value="1"
          :true-value="0"
          class="mb-0 pb-0"
        ></v-switch>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-0">
        <v-form ref="form" lazy-validation>
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="6">
              <p
                class="body-regular warning-content mb-6"
                t-data="warning-content"
              >
                All fields are required.
              </p>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="3">
              <p class="edit-field-label sub-body-bold" t-data="version-info-label">Min OS Version</p>
              <v-text-field
                outlined
                required
                type="number"
                dense
                class="subtitle-2"
                v-model="appVersionObject.device_min_version"
                placeholder="Enter Min OS Version"
                min="1"
                :rules="minOsVersionRules"
              ></v-text-field>
            </v-col>
             <v-col cols="3">
              <p class="edit-field-label sub-body-bold" t-data="version-info-label">App Version</p>
              <v-text-field
                outlined
                required
                dense
                class="subtitle-2"
                v-model="appVersionObject.app_version"
                placeholder="Enter App Version"
                :rules="appVersionRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Contents -->
          <!-- Thai -->
          <v-expansion-panels :value="0" class="mb-4">
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                disable-icon-rotate
              >
                <h6 class="mb-0 card-title">
                  Update Description
                </h6>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                    <v-row>
                    <v-col cols="12">
                      <p class="edit-field-label sub-body-bold" t-data="version-info-label">Thai</p>
                      <v-text-field
                        placeholder="Description Thai"
                        outlined
                        dense
                        required
                        v-model="appVersionObject.description_th"
                        :rules="[v => !!v || 'Please enter updated Thai Description.']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                       <v-col cols="12">
                      <p class="edit-field-label sub-body-bold" t-data="version-info-label">English</p>
                      <v-text-field
                        placeholder="Description English"
                        outlined
                        dense
                        required
                        v-model="appVersionObject.description_en"
                        :rules="[v => !!v || 'Please enter updated English Description.']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row
      class="d-flex align-start mb-3"
      t-data="footer-row"
      style="margin-left: 1px; margin-right: 0;"
    >
      <created-detail
        :data-object="appVersionObject"
        :show-create="false"
      />
    </v-row>

    <!-- Form -->
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import CreatedDetail from '@/components/CreatedDetail'
import AlertConfirm from '@/components/AlertConfirm'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    'v-alert-error': AlertError,
    'v-alert-confirm': AlertConfirm,
    CreatedDetail,
  },
  data() {
    return {  
      errorMessage: "",
      isError: false,
      isCancel: false,
      currentVersion: null,
      appVersionRules: [
      v => !!v || 'Please enter App Version.', 
      v => v >= this.currentVersion || `Version must not lower than current version`],
      minOsVersionRules: [
      v => !!v || 'Please enter Min OS Version.', 
      v => v >= 1 || `Min OS Version must be greater than 1`],
    }
  },
  watch: {
    appVersionObject(value) {
      this.currentVersion = value.app_version
    },
  },
  destroyed() {
    this.isSuccess = true
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  async created() {
    await this.setAppVersionId(this.$route.params.id)
  },
  computed: {
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Force Update', links: true, to: '/force-updates'},
        {text: 'Edit', links: false}
      ]
    },
    ...mapState('forceUpdate', [
      'appVersionObject',
      'isLoading'
    ])
  },
  methods: {
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'force-update-list'})
    },
    async save() {
      if (!this.isSaveButtonDisable()) {
        if (this.validate()) {
          this.isSave = true
          const payload = {versionObj: this.appVersionObject, id: this.$route.params.id}
          let status = await this.updateVersion(payload)
          if (status) {
            this.$router.push({
              name: 'force-update-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
            return (this.isSuccess = true)
          }
          this.errorMessage = "Server Error!";
          return (this.isError = true)
        }
      } else {
        this.isError = true
      }
    },
    isSaveButtonDisable() {
      if (
        !this.appVersionObject.device_min_version ||
        !this.appVersionObject.app_version ||
        this.appVersionObject.description_en === null ||
        this.appVersionObject.description_th === null
      ) {
        return true
      }

      return false
    },
    validate() {
      if (this.$refs.form.validate()) {
        return true
      }
      return false
    },
    ...mapActions('forceUpdate', [
      "setAppVersionId",
      "updateVersion",
      "resetState"
    ])
  }
}
</script>

<style scoped>
.save-package-button {
  color: var(--primary-100-color) !important;
}
</style>
